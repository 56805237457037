<template>
    <span :class='{
        "box-badge": true,
        "success": code === "published",
        "danger": code === "draft",
        "warning": code === "ready"}'> {{ getStateLabel(code) }}
    </span>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const commonStore = createNamespacedHelpers('Common');

export default {
    computed: {
        ...commonStore.mapGetters(['publish_state'])
    },
    methods: {
        test(code){
            console.log(this.publish_state);
            return code;
        },
        getStateLabel(code){
            const state = this.publish_state(code);
            if (state.label) return state.label;
            return "-"
        },
    },
    props: {
        code: {
            type: String,
            required: true
        }
    }
}
</script>
