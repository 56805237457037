export default {
    data(){
        return {
            dd_thu_types: [
                { label: 'Daily', value: 'reg' },
                { label: 'Weekly Top News', value: 'tpn' },
            ],
        };
    },
    methods: {
        ThuTypeLabel(val){
            let name = '-';
            this.dd_thu_types.forEach(s => {
                if (s.value === val) {
                    name = s.label;
                }
            });
            return name;
        }
    }
}
