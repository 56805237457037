<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco The Update</h4>
                </div>
                <DataTable lazy ref='dt'
                           :totalRecords='totalRecords'
                           :value='rows'
                           @page='onPage($event)'
                           @sort='onSort($event)'
                           :paginator='true'
                           class='p-datatable-gridlines'
                           :globalFilterFields="['status', 'date_from', 'date_to']"
                           :rows='search_params.limit'
                           v-model:filters='filters'
                           dataKey='id'
                           :rowHover='true'
                           filterDisplay='row'
                           :loading='loading'
                           responsiveLayout='scroll'
                >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            @refresh='refreshList()'
                            @add='$router.push({ path: gotoNew() })'
                            :hide-filter='true'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='date' header='Data di pubblicazione' :sortable='true' style='width:30rem'
                            :showFilterMenu='false'>
                        <template #filter='{}'>
                            <div class='grid'>
                                <div class='col-5 pb-0'>
                                    <Calendar
                                        :showTime="false"
                                        inputId="pub_at"
                                        v-model="filters['date_from'].value"
                                        dateFormat="dd/mm/yy"
                                    />
                                </div>
                                <div class='col-5 pb-0'>
                                    <Calendar
                                        :showTime="false"
                                        inputId="pub_at"
                                        v-model="filters['date_to'].value"
                                        dateFormat="dd/mm/yy"
                                    />
                                </div>
                                <div class='col-2 pb-0'>
                                    <button @click="refreshList" class="p-column-filter-clear-button p-link mt-1" type="button"
                                            v-if="filters['date_to'].value || filters['date_from'].value">
                                        <span class="pi pi-filter-slash">
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </template>
                        <template #body='slotProps'>
                            {{ ts2date(slotProps.data.date) }}
                        </template>
                    </Column>


                    <Column field='status' header='Pubblicazioni' style='min-width:2rem' :filterMatchModeOptions="matchModes" class='hide-filter'>
                        <template #filter='{}'>
                            <InputText type='text' v-model="filters['status'].value"
                                       class='p-column-filter'
                                       placeholder='Cerca per testo nei titoli' />
                        </template>
                        <template #body='slotProps'>
                            <ul v-if='slotProps.data.t' class='publish_states_x_language my-0'>
                                <li :key='"thu_ps_"+lang' v-for='lang in Object.keys(slotProps.data.t)'>
                                    <Flag :code="lang"  :width='24' :class="{'mr-1': true}"/>
                                    <PublishStateBadge :code='slotProps.data.t[lang]'/>
                                </li>
                            </ul>
                        </template>
                    </Column>
                    <Column field='type' class='text-center'>
                        <template #body='slotProps'>
                            <Chip :label='ThuTypeLabel(slotProps.data.type)' :class='{[slotProps.data.type]: true}'>

                            </Chip>
                        </template>
                    </Column>

                    <Column field='id' style='min-width:6rem' class='hide-filter'>
                        <template #filter='{}'>
                            <Button label='FILTRA' @click='refreshList'></Button>
                        </template>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='text-purple-300 hover:text-purple-400 pointer ml-2'
                            title='Apri in nuovo Tab'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-blue-400 hover:text-blue-600 pointer ml-2'
                            title='Apri nello stesso Tab'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        /></template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)+"/it"' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 p-mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                        </template>
                    </Column>

                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Cont/TheUpdate');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import dd_thu_types from '../../../mixins/dd_thu_types';
import PublishStateBadge from '../../../components/4books/PublishStateBadge';
import Flag from '../../../components/Flag';
import ListHeader from '../../../components/4books/ListHeader';
import tools from '../../../libs/tools';

export default {
    components: {
        ListHeader,
        PublishStateBadge,
        Flag
    },
    data() {
        return {
            filters: {
                'status': { value: '', matchMode: 'equals' },
                'date_from': { value: '', matchMode: 'equals' },
                'date_to': { value: '', matchMode: 'equals' },
            },
            matchModes: [],
            search_params: {
                limit: 10,
                order: 1,
                sortBy: 'email',
                page: 1,
                email: '',
            },
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading', 'page', 'pages']),
        totalRecords() {
            return this.pages * this.search_params.limit;
        },
    },
    created() {
        this.initFilters();
    },
    mounted() {
        if (!this.rows || this.rows.length === 0){
            this.refreshList();
        }

    },
    methods: {
        ...mapActions(['search']),
        cut(str, chars) {
            if (str.length <= chars) return str;
            return str.substring(0, chars) + '...';
        },
        onPage(event) {
            this.search_params.page = event.page + 1;
            this.refreshList();
        },
        onSort(event) {
            this.search_params.sortBy = event.sortField;
            this.search_params.order = event.sortOrder;
            this.refreshList();
        },
        refreshList() {
            const payload = {
                ...this.search_params,
                text: this.filters.status.value
            };
            if (this.filters.date_from.value){
                payload.date_from = tools.time.toEnglish(this.filters.date_from.value);
            }
            if (this.filters.date_to.value){
                payload.date_to = tools.time.toEnglish(this.filters.date_to.value);
            }
            this.search(payload).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.filters.status.value = '';
            this.filters.date_to.value = null;
            this.filters.date_from.value = null;
        },
        clearFilter() {
            this.initFilters();
        },
    },
    mixins: [
        Notifications,
        Navigations,
        dd_thu_types
    ],
};
</script>

<style lang='scss'>
    .hide-filter{
        .p-column-filter-menu-button {
            display: none;
        }
    }
    .tpn {
        background: var(--orange-400);
        color: white
    }
    .reg {
        background: var(--green-600);
        color: white
    }
    .p-chip-text{
        padding-left: 1rem;
        padding-right: 1rem;
    }
</style>
